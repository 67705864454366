<template>
    <div
      class="min-w-screen min-h-screen bg-secondary flex items-center justify-center px-5 py-5"
    >
      <div
        class="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
        style="max-width: 1000px"
      >
        <div class="md:flex w-full">
          <div
              class="hidden imageDivClass md:flex scale-image"
          >
            <img class="hidden md:flex scale-image" :src="backgroundRegister" v-if="backgroundRegister && !loading" />
            <img
                :alt="title"
                class="hidden md:flex scale-image"
                src="/the-one-stellenbosch/images/login.jpg"
                v-if="!backgroundRegister && !loading"
            />
            <PageLogo />
          </div>
            <div class="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div class="text-center mb-5">
            <ToggleSwitch value="REGISTER"/>
            <p>Sign up to view the {{title}} Price List &amp; Plans</p>
          </div>

          <form @submit.prevent="submit" @keyup.enter.prevent="submit">
            <div class="flex -mx-3">
              <div class="w-1/2 px-3">
                <label for="register-firstName" class="lb-icon-text-label">First name</label>
                <div class="flex">
                  <div
                    class="lb-icon-text-icon"
                  >
                    <NameIcon />
                  </div>
                  <input
                    v-model="form.firstName"
                    type="text"
                    class="w-full -ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
                    placeholder="" />
                </div>
                <span
                class="text-xs text-red-600"
                :class="{ '': $v.form.firstName.$error, 'invisible': !$v.form.firstName.$error }">
                  Required</span>
              </div>

              <div class="w-1/2 px-3">
                <label for="register-lastName" class="lb-icon-text-label"
                  >Last name</label
                >
                <div class="flex">
                  <div
                    class="lb-icon-text-icon"
                  >
                    <NameIcon />
                  </div>
                  <input
                  v-model="form.lastName"
                    type="text"
                    class="w-full -ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
                    placeholder=""
                  />
                </div>
                <span
                class="text-xs text-red-600"
                :class="{ '': $v.form.lastName.$error, 'invisible': !$v.form.lastName.$error }">
                  Required</span>
              </div>
            </div>
            <div class="flex -mx-3">
              <div class="w-full px-3">
                <label for="register-email" class="lb-icon-text-label">Email</label>
                <div class="flex">
                  <div
                    class="lb-icon-text-icon"
                  >
                    <EmailIcon />
                  </div>
                  <input
                    v-model="form.email"
                    type="email"
                    class="w-full -ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary"
                    placeholder=""
                  />
                </div>
                <span
                class="text-xs text-red-600"
                :class="{ '': $v.form.email.$error, 'invisible': !$v.form.email.$error }">
                  Please enter a valid email address</span>
              </div>
            </div>

            <div class="flex -mx-3">
              <div class="w-full px-3">
                <label for="register-password" class="lb-icon-text-label"
                  >Password</label
                >
                <div class="flex">
                  <div
                    class="lb-icon-text-icon"
                  >
                    <PasswordIcon />
                  </div>
                  <input
                    v-if="showPassword"
                    v-model="form.password"
                    type="text"
                    class="password-content"
                    placeholder=""
                  />
                  <input
                    v-else
                    v-model="form.password"
                    type="password"
                    class="password-content"
                    placeholder=""
                  />
                  <input
                    class="hidden"
                    id="utm_source"
                  />
                  <div
                    class="password-shower"
                    @click="toggleShow"
                  >
                    <span >
                      <i v-if="showPassword" class="password-showerBtn"><EyeOutlineIcon/></i>
                      <i v-if="!showPassword" class="password-showerBtn"><EyeOffOutlineIcon/></i>
                    </span>
                  </div>
                </div>
                <span
                class="text-xs text-red-600"
                :class="{ '': $v.form.password.$error, 'invisible': !$v.form.password.$error }">
                  Please enter a valid password. Must include more than 7 characters</span>
              </div>
            </div>

            <div class="flex -mx-3">
              <div class="w-full px-3 mb-2">
                <label for="" class="lb-icon-text-label"
                  >Contact Number</label
                >
                <div class="flex">
                  <VuePhoneNumberInput
                  v-model="form.contactNumber"
                  @update="updatePhoneNumber"
                  class="w-full outline-none focus:border-primary"
                  size="md"
                  color="#aa9461"
                  default-country-code="ZA" />
                </div>
                <span
                class="text-xs text-red-600"
                :class="{ '': $v.form.contactNumber.$error, 'invisible': !$v.form.contactNumber.$error }">
                  Required</span>
              </div>
            </div>

            <div class="flex -mx-3">
              <div class="w-full px-3 mb-0">
                <input v-model="form.tac" id="register-tac" type="checkbox" class="mr-2 rounded text-primary" />
                <label for="register-tac" class="lb-icon-text-label"
                  >Accept <a target="_blank" :href="tacLink" class="underline">Terms and Conditions</a></label
                >
                <span
                class="block w-full text-xs text-red-600"
                :class="{ '': $v.form.tac.$error, 'invisible': !$v.form.tac.$error }">
                  Please accept our Terms &amp; Conditions to proceed</span>
              </div>
            </div>

            <div class="flex -mx-3 mt-5">
              <div class="w-full px-3 mb-5">
                <button
                  v-if="loading"
                  type="button"
                  class="flex flex-row justify-center w-full max-w-xs mx-auto bg-primary hover:bg-primary focus:bg-primary text-white rounded-lg px-3 py-3 font-semibold"
                  disabled="">
                  <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                    Loading
                </button>

                <button
                  v-else
                  class="block w-full max-w-xs mx-auto bg-primary hover:bg-primary focus:bg-primary text-white rounded-lg px-3 py-3 font-semibold"
                  type="submit"
                >
                  REGISTER NOW
                </button>

                <router-link
                class="block mt-5 text-center text-sm transform hover:scale-110"
                :to="{name: 'login'}"
                >Already have an account? Login.</router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

import firebase from 'firebase'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import {profilesCollection, settingsCollection} from '@/firebase'

import {required, email, minLength, sameAs} from "vuelidate/lib/validators";
import EmailIcon from 'vue-material-design-icons/At'
import NameIcon from 'vue-material-design-icons/Account'
import PasswordIcon from 'vue-material-design-icons/Lock'
import PageLogo from '@/components/app/PageLogo.vue';

import {APP_COOKIE_UTM_SOURCE, APP_TERMS_AND_CONDITIONS_LINK, APP_TITLE} from '@/Constants'
import ToggleSwitch from "@/components/ToggleSwitch";
import EyeOutlineIcon from "vue-material-design-icons/EyeOutline";
import EyeOffOutlineIcon from "vue-material-design-icons/EyeOffOutline";

export default {
  name: 'Login',
  components: {
    EmailIcon,
    NameIcon,
    PasswordIcon,
    VuePhoneNumberInput,
    ToggleSwitch,
    EyeOutlineIcon,
    EyeOffOutlineIcon,
    PageLogo
  },
  data: () => ({
    form: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      contactNumber: "",
      e164ContactNumber: null,
      formatNationalContactNumber: null,
      tac: false,
    },
    loading: false,
    title: APP_TITLE,
    tacLink: APP_TERMS_AND_CONDITIONS_LINK,
    priceListUrl: '',
    backgroundRegister: null,
    logoSignIn: null,
    showPassword: false,
  }),
  validations: {
    form: {
      firstName: {required},
      lastName: {required},
      email: {required, email},
      password: {required, minLength: minLength(7)},
      contactNumber: {required},
      tac: {required, sameAs: sameAs(function() {return true})},
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    submit() {
      this.loading = true

      this.$v.form.$touch()
      if(this.$v.form.$error) {
        this.loading = false
        return
      }

      const timeStamp = firebase.firestore.Timestamp.now()

      const profileData = {
          role: 'user',
          accountDisabled: false,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          e164ContactNumber: this.form.e164ContactNumber,
          formatNationalContactNumber: this.form.formatNationalContactNumber,
          email: this.form.email.toLowerCase(),
          acceptedTAC: this.form.tac,
          utmTags: this.utmTag,
          unitShortlist: [],
          unitShortlistIDs: [],
          answeredDiscountSurvey: false,

          discountSurveyTimestamp: null,
          discountSurveyAge: null,
          discountSurveyCity: null,
          discountSurveyCompany: null,
          discountSurveyHouseHoldIncome: null,
          discountSurveyJobTitle: null,
          discountSurveyMaritalStatus: null,
          discountSurveyNumberOfChildren: null,
          discountSurveyWhyInterested: null,
          discountSurveyWhyInterestedOther: null,

          created: timeStamp,
          modified: timeStamp,
        }

      this.loading = true
      firebase
      .auth()
      .createUserWithEmailAndPassword(this.form.email, this.form.password)
      .then(function(data) {
        console.log(data)
        const userID = data.user.uid

        console.log(profileData)

        profilesCollection.doc(userID).set(profileData)
      })
      .then(() => {
        this.$router.replace({name: 'home'})
      })
      .catch(err => {
        this.loading = false
        console.log(err)
        this.$notify({
          group: 'global',
          type: 'error',
          text: err.message
        })

        return
      })

      this.loading = false
    },
    updatePhoneNumber(e) {
      this.form.e164ContactNumber = e.e164
      this.form.formatNationalContactNumber = e.formatNational
    },
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    },
    utmTag: function() {
      const name = APP_COOKIE_UTM_SOURCE
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      const utmSource = parts.pop().split(';').shift()
      var utmInitArray = []

      if(!isNil(utmSource) && !isEmpty(utmSource)) {
        var utmTag = `register-${utmSource}`
        utmInitArray.push(utmTag)
      }

      return utmInitArray
    },
  },
  created() {
    settingsCollection.doc("globalSettings").onSnapshot((doc) => {
      this.backgroundRegister = doc.data().backgroundRegister;
      this.logoSignIn = doc.data().logoSignIn;
      this.loading = false;
    });
  },
  mounted() {
    // Read the cookie.
    const name = APP_COOKIE_UTM_SOURCE
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    console.log(parts)
    if (parts.length === 2) {
      document.getElementById('utm_source').setAttribute("value", parts.pop().split(';').shift())
    }
  },
}
</script>

<style>
.scale-image {
  width: 100%;
  height: auto;
}
.password-content {
  width: 90%;
  margin-left: -2.5rem;
  padding: .5rem .75rem .5rem 2.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  border-radius: .25rem 0 0 .25rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.password-content:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 148, 97, var(--tw-ring-opacity));
}

.password-shower {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  border-radius: 0 .25rem .25rem 0;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.password-shower:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(170, 148, 97, var(--tw-ring-opacity));
}

.password-showerBtn {
  display: flex;
  justify-content: center;
  align-content: center;
}
.imageDivClass {
  position: relative;
}
.signIn-logo {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    width: 80%;
    margin: 0 auto;
}
</style>
